'use client';

import React from 'react';
import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs';
import type Entity from '@ant-design/cssinjs/es/Cache';
import { useServerInsertedHTML } from 'next/navigation';
const StyledComponentsRegistry = ({
  children
}: React.PropsWithChildren) => {
  const cache = React.useMemo<Entity>(() => createCache(), []);
  useServerInsertedHTML(() => <style id="antd" dangerouslySetInnerHTML={{
    __html: extractStyle(cache, true)
  }} />);
  return <StyleProvider cache={cache} data-sentry-element="StyleProvider" data-sentry-component="StyledComponentsRegistry" data-sentry-source-file="AntdRegistry.tsx">{children}</StyleProvider>;
};
export default StyledComponentsRegistry;